<script lang="ts" setup>
import { ref } from "vue"
import { useRouter, useRoute } from "vue-router"

import { getChildById } from "@/services/Child"
import {
  isChildOlderThan2,
  createOrReplaceGrowthAssessment,
  getGrowthAssessmentForChild,
} from "@/services/GrowthAssessment"
import { getStandSchema } from "@/schemas/GrowthAssessment"
import { setCurrentViewContext } from "@/utils/GlobalState"
import ChildHeader from "@/components/children/ChildHeader.vue"
import PageLoading from "@/components/PageLoading.vue"

const route = useRoute()
const router = useRouter()
const child = ref(null)
const childId = parseInt(route.params.childId as string)
const assessmentId = parseInt(route.params.assessmentId as string)
const growthAssessment = ref(null)
const pageReady = ref(false)
let schema: Array<object>

async function getData() {
  child.value = await getChildById(childId)
  growthAssessment.value = await getGrowthAssessmentForChild(assessmentId)
  const showStandField = isChildOlderThan2(child.value.dob, growthAssessment.value.dateOfAssessment)
  schema = getStandSchema(showStandField)
  setCurrentViewContext(child.value, "child")
  pageReady.value = true
}

async function submitForm(data) {
  if ("canChildStand" in data) {
    growthAssessment.value.canChildStand = data.canChildStand
  }
  if ("canStraightenLegs" in data) {
    growthAssessment.value.canStraightenLegs = data.canStraightenLegs
  }
  await createOrReplaceGrowthAssessment(child.value, growthAssessment.value)
  router.push({ name: "GrowthAssessmentNotes", params: { childId, assessmentId } })
}

getData()
</script>

<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      {{ $gettext("Growth") }}
    </h1>
    <FormKit
      v-if="pageReady"
      type="form"
      :submit-label="$gettext('Save')"
      :submit-attrs="{ inputClass: 'button is-primary' }"
      @submit="submitForm"
    >
      <FormKitSchema
        :schema="schema"
        :data="growthAssessment"
      />
    </FormKit>
    <PageLoading v-else />
  </article>
</template>
