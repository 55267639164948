<template>
  <div :class="['growth-chart-outer-wrapper', `growth-chart-${assessment.child.sex}`]">
    <div class="growth-chart-inner-wrapper">
      <div class="chart-placeholder">
        <IconSpinner v-if="!problemsRenderingChart" />
        <div
          v-else
          class="message is-warning"
        >
          <p class="message-body m-0">
            {{ $gettext("There was a problem displaying this growth chart.") }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="dirtyDataExcluded"
    class="dirty-data-warning"
  >
    <strong>{{ $gettext("Note:") }}</strong>
    {{ $gettext("One or more past measurements are not included in growth charts because they were later found to be possibly inaccurate.") }}
  </div>
</template>
<script lang="ts" setup>
  import { ref, watchPostEffect, onUnmounted } from "vue"
  import { useDebounceFn } from '@vueuse/core'

  import IconSpinner from "@/components/svg/IconSpinner.vue"
  import { GrowthAssessment } from "@/models/GrowthAssessment"
  import { getGrowthChartDisplayer } from "@/utils/GrowthCharts"

  const props = defineProps({
    assessment: {
      type: GrowthAssessment,
      required: true,
    },
    zScoreLineData: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    dirtyDataExcluded: {
      type: Boolean,
      default: false,
    }
  })
  const problemsRenderingChart = ref(false)
  let displayGrowthChartDebounced: () => void


  watchPostEffect(() => {
    if (displayGrowthChartDebounced) {
      window.removeEventListener("resize", displayGrowthChartDebounced)
    }
    problemsRenderingChart.value = false
    const displayGrowthChart = getGrowthChartDisplayer(props.chartData, props.zScoreLineData)
    displayGrowthChartDebounced = useDebounceFn(() => { displayGrowthChart() }, 100)

    try {
      displayGrowthChart()
      window.addEventListener("resize", displayGrowthChartDebounced)
    }
    catch(error) {
      // Probably really extreme measurements.
      console.log(error)
      problemsRenderingChart.value = true
    }
  })

  onUnmounted(() => {
    window.removeEventListener("resize", displayGrowthChartDebounced)
  })
</script>
