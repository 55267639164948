import dayjs from "dayjs"
import { isProxy, toRaw } from "vue"

import { db } from "@/db"
import { getCurrentUsersName } from "@/services/Account"
import { queuePendingUpload } from "@/services/Upload"

// Given the array of existing assessment notes for an assessment
// and the content of a new one to add,
// - determine an appropriate "local id" for it
// - add today's date
// - add the current user
// - prepend the new note object to the array of notes
// Then return the newly modified array.
export function appendAssessmentNote(existingNotes: Array<object>, newNoteContent: string) {
  existingNotes = existingNotes ?? []
  const ids = existingNotes.map((item: { id: number }) => item.id)
  const id = (ids.length ? Math.max(...ids) : 0) + 1
  const account = getCurrentUsersName()
  const day = dayjs().format("YYYY-MM-DD")

  existingNotes.splice(0, 0, { note: newNoteContent, day, account, id })
  return existingNotes
}

// Append @note to the existing "notes" array on @assessment, appropriately marked up with author and date.
// Then save out to local database, and possibly queue for upload.
export async function addNoteToAssessment(tableName: string, assessment: { id: number, notes: Array<object> }, note: string, markForUpload: boolean) {
  // 1: update the assessment objet with the new note content. That means:
  // - determine an appropriate "local id" for the note
  // - add today's date
  // - add the current user
  // - prepend the new note object to the assessment's array of notes
  assessment.notes = appendAssessmentNote(assessment.notes ?? [], note)

  // 2: Save out new note to db
  const table = db[tableName]
  const notes = isProxy(assessment.notes) ? toRaw(assessment.notes) : assessment.notes
  await db.transaction("rw", table, db.pendingUploads, async () => {
    const promises = [
      table.update(assessment.id, { notes })
    ]
    // 3: Maybe mark for upload
    if (markForUpload) {
      const pendingUploadTag = (tableName.endsWith("s") ? tableName.slice(0, -1) : tableName) + "Note"
      promises.push(queuePendingUpload({ type: pendingUploadTag, localItemId: assessment.id }))
    }
    return Promise.all(promises)
  })
}
