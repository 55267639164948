<script lang="ts" setup>
import { ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import dayjs from "dayjs"

import {
  getChildById,
  updateBeforeAssessing,
 } from "@/services/Child"
import {
  createOrReplaceGrowthAssessment,
  getGrowthAssessmentForChild,
} from "@/services/GrowthAssessment"
import { getDateOfAssessmentSchema } from "@/schemas/GrowthAssessment"
import { setCurrentViewContext, showFlashMessage } from "@/utils/GlobalState"
import ChildHeader from "@/components/children/ChildHeader.vue"
import PageLoading from "@/components/PageLoading.vue"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const route = useRoute()
const router = useRouter()
const child = ref(null)
const childId = parseInt(route.params.childId as string)
let assessmentId = parseInt(route.params.assessmentId as string)
const growthAssessment = ref(null)
const pageReady = ref(false)
const today = new Date()

// This one is a little funny; we need to do some massaging to set the initial
// form values (in case user has hit the back button).
const initial = {}

async function getData() {
  child.value = await getChildById(childId)
  setCurrentViewContext(child.value, "child")
  await updateBeforeAssessing(child.value)
  .catch((error: Error) =>  {
    if (error.name === "CONNECTIVITY_REQUIRED") {
      showFlashMessage(({ msg: error.message }))
    }
    else {
      showFlashMessage({ msg: $gettext("Something unexpected happened") })
      console.log(error)
    }
  })
  growthAssessment.value = await getGrowthAssessmentForChild(assessmentId, child.value)
  if (growthAssessment.value){
    assessmentId = growthAssessment.value.id
    if (growthAssessment.value.dateOfAssessment) {
      const doa = dayjs(growthAssessment.value.dateOfAssessment)
      initial["dateOfAssessment"] = doa.format("YYYY-MM-DD")
      initial["assessmentIsToday"] = doa.isSame(today, "day")
    }
  }
  else {
    growthAssessment.value = { childId }
  }
  pageReady.value = true
}

async function submitForm(data) {
  // Use day.js to get around baffling Date api.
  growthAssessment.value.dateOfAssessment = data.assessmentIsToday ? today : dayjs(data.dateOfAssessment).toDate()
  assessmentId = await createOrReplaceGrowthAssessment(child.value, growthAssessment.value)
  router.push({ name: "GrowthStandForm", params: { childId, assessmentId } })
}

getData()
</script>

<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      {{ $gettext("Growth") }}
    </h1>
    <template v-if="pageReady">
      <FormKit
        type="form"
        :submit-label="$gettext('Save')"
        :submit-attrs="{ inputClass: 'button is-primary' }"
        @submit="submitForm"
      >
        <FormKitSchema
          :schema="getDateOfAssessmentSchema(child)"
          :data="initial"
        />
      </FormKit>
    </template>
    <PageLoading v-else />
  </article>
</template>
