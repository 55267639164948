<template>
  <div class="columns">
    <div class="column is-one-third">
      <h4 class="metric-label">
        <strong>{{ content.label }}</strong>
        <span class="muted">{{ content.userFacingMeasurement }}</span>
      </h4>
      <div :class="{ tag: true, 'is-danger': content.stoplightColor == 'red', 'is-warning': content.stoplightColor == 'yellow', 'is-success': content.stoplightColor == 'green' }">
        {{ content.verdict }}
      </div>
      <ul class="list mt-4">
        <li
          v-for="int in content.interpretations"
          :key="int.contentId"
        >
          {{ int.content }}
          <a
            v-if="int.learnMore"
            class="learn-more"
            @click="showLearnMore(int.learnMore)"
          >
            {{ $gettext("Learn more") }}
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="pageReady"
      class="column is-two-thirds"
    >
      <div
        v-if="!chartData"
        class="muted"
      >
        {{ $gettext("Growth chart not available") }}
      </div>
      <template v-else-if="chartData?.length == 1">
        <MuacCutoffChart
          v-if="chartData[0].chartType ==='muac_cutoff'"
          :assessment="assessment"
          :dirty-data-excluded="dirtyDataExcluded"
        />
        <GrowthChart
          v-else
          :assessment="assessment"
          :chart-data="chartData[0]"
          :z-score-line-data="zScoreLineData"
          :dirty-data-excluded="dirtyDataExcluded"
        />
      </template>
      <template v-else-if="chartData.length > 1">
        <!-- MUAC shouldn't ever be part of multi-chart metrics, so not checking. -->
        <div class="slide">
          <GrowthChart
            :key="currentSlideNo"
            :assessment="assessment"
            :chart-data="chartData[currentSlideNo]"
            :z-score-line-data="zScoreLineData"
            :dirty-data-excluded="dirtyDataExcluded"
          />
        </div>
        <div class="slider-controls">
          <a
            :class="{ disabled: currentSlideNo === 0 }"
            @click.prevent="changeSlide(-1)"
          >
            <IconLeftArrow />
          </a>
          <a
            :class="{ disabled: currentSlideNo >= chartData.length - 1 }"
            @click.prevent="changeSlide(1)"
          >
            <IconRightArrow />
          </a>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, nextTick } from "vue"

  import MuacCutoffChart from "@/components/assessments/MuacCutoffChart.vue"
  import GrowthChart from "@/components/assessments/GrowthChart.vue"
  import IconLeftArrow from "@/components/svg/IconLeftArrow.vue"
  import IconRightArrow from "@/components/svg/IconRightArrow.vue"
  import { GrowthAssessment } from "@/models/GrowthAssessment"
  import { getZScoreLineData } from "@/utils/GrowthCharts"

  const emit = defineEmits(["currentTab", "learnMoreContent"])
  const props = defineProps({
    assessment: {
      type: GrowthAssessment,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    // Show users a warning that some assessments were excluded bc of dirty data?
    dirtyDataExcluded: {
      type: Boolean,
      required: true,
    }
  })

  const zScoreLineData = ref(null)
  const pageReady = ref(false)
  const currentSlideNo = ref(0)
  const transitionName = ref("prev")

  async function getData() {
    zScoreLineData.value = await getZScoreLineData(props.assessment)
    // This is a superstitious attempt at avoiding fleeting issues
    // where Z score data don't seem to be available.
    await nextTick()
    pageReady.value = true
  }

  function changeSlide(incr) {
    transitionName.value = incr == 1 ? "next" : "prev"
    currentSlideNo.value += incr
  }

  function showLearnMore(content) {
    emit("learnMoreContent", content)
  }

  getData()
</script>

<style scoped lang="scss">
  h4 strong {
    font-size: 1.25em;
    color: hsl(0, 0%, 14%); /* $black-ter; same as .title */
    display: inline-block;
    margin-right: 0.5em
  }
  .tag {
    font-size: 100%;
  }
  .slider-controls {
    display: flex;
    justify-content: center;
    a {
      display: block;
      height: 2em;
      width: 2em;
      margin: 0 1em;
      svg {
        fill: hsl(0, 0%, 48%);  /* Bulma $grey */
      }
      &.disabled {
        pointer-events: none;
        svg {
          fill: hsl(0, 0%, 86%);  /* Bulma $grey-lighter*/
        }
      }
    }
  }
</style>
