import dayjs from "dayjs"

import { getYesNoOptions } from "@/utils/Options"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

export function getDateOfAssessmentSchema({ dob }) {
  const min = dayjs(dob).format("YYYY-MM-DD")
  const max = dayjs().format("YYYY-MM-DD")
  const tomorrow = dayjs().add(1, "d").format("YYYY-MM-DD")
  return [
    {
      $formkit: "radio",
      id: "assessmentIsToday",
      name: "assessmentIsToday",
      value: "$assessmentIsToday",
      label: $gettext("Was this assessment performed today?"),
      options: getYesNoOptions(),
      validation: "required",
    },
    {
      $formkit: "date",
      name: "dateOfAssessment",
      value: "$dateOfAssessment",
      label: $gettext("Date of Assessment"),
      min, max,
      validation: `required|date_before:${tomorrow}`,
      "validation-messages": { date_before: $gettext("This date cannot be in the future.") },
      if: "$get(assessmentIsToday).value == false",
    },
  ]
}

export function getStandSchema(showStandField: boolean) {
  const schema = [
    {
      $formkit: "radio",
      id: "canStraightenLegs",
      name: "canStraightenLegs",
      value: "$canStraightenLegs",
      label: $gettext("Can child straighten legs?"),
      options: getYesNoOptions(),
      validation: "required",
    }
  ]
  if (showStandField) {
    schema[0]["if"] = "$get(canChildStand).value == false"
    schema.splice(0, 0, {
      $formkit: "radio",
      id: "canChildStand",
      name: "canChildStand",
      value: "$canChildStand",
      label: $gettext("Can this child stand?"),
      options: getYesNoOptions(),
      validation: "required",
    })
  }
  return schema
}


export function getHeightSchema() {
  return [
    {
      $formkit: "number",
      name: "lengthInCm",
      value: "$lengthInCm",
      label: $gettext("Height (in cm)"),
      step: 0.1,
      min: 30,
      max: 200,
    },
  ]
}

export function getLengthSchema() {
  return [
    {
      $formkit: "number",
      name: "lengthInCm",
      value: "$lengthInCm",
      label: $gettext("Length (in cm)"),
      step: 0.1,
      min: 30,
      max: 200,
    },
  ]
}

export function getMuacSchema() {
  return [
    {
      $formkit: "number",
      name: "muacInCm",
      value: "$muacInCm",
      label: $gettext("Arm Size (in cm)"),
      step: 0.1,
      min: 6,
      max: 40,
    },
  ]
}

export function getWeightSchema() {
  return [
    {
      $formkit: "number",
      name: "weightInKilograms",
      value: "$weightInKilograms",
      label: $gettext("Weight (in kg)"),
      step: 0.01,
      min: 1,
      max: 125,
    },
  ]
}

export function getHeadSizeSchema() {
  return [
    {
      $formkit: "number",
      name: "headCircumferenceInCm",
      value: "$headCircumferenceInCm",
      label: $gettext("Head Size (in cm)"),
      step: 0.1,
      min: 20,
      max: 100,
    },
  ]
}
