<script setup>
  import { useWindowSize } from '@vueuse/core'
  import { useRoute } from "vue-router"
  import { ref } from "vue"

  import { getChildById } from "@/services/Child"
  import { getGrowthChartData } from "@/utils/GrowthCharts"
  import { getAllSavedGrowthAssessmentsForChild } from "@/services/GrowthAssessment"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { GrowthAssessment } from "@/models/GrowthAssessment"
  import { gettext } from "@/utils/Translation"
  import { getZScoreLineData } from "@/utils/GrowthCharts"

  import ChildHeader from "@/components/children/ChildHeader.vue"
  import MuacCutoffChart from "@/components/assessments/MuacCutoffChart.vue"
  import GrowthChart from "@/components/assessments/GrowthChart.vue"

  const route = useRoute()
  const chartData = ref(null)
  const currentTab = ref("measurements")
  const currentAssessment = ref(null)
  const child = ref(null)
  const assessments = ref([])
  const tabTitles = ref(null)
  const childId = parseInt(route.params.childId)
  const assessmentId = parseInt(route.params.assessmentId)
  const showMeasurements = ref(true)
  const showZScores = ref(false)
  const { $gettext } = gettext
  const { width } = useWindowSize()
  const zScoreLineData = ref(null)

  async function getData() {
    child.value = await getChildById(childId)
    setCurrentViewContext(child.value, "child")
    const assessmentData = await getAllSavedGrowthAssessmentsForChild(childId)
    assessmentData.reverse()
    for (let i=0; i<assessmentData.length; i++) {
      let ga = new GrowthAssessment(child.value, assessmentData[i])
      ga.setZScores()
      assessments.value.push(ga)
      if (ga.id == assessmentId) {
        currentAssessment.value = ga
      }
    }
    chartData.value = await getGrowthChartData(currentAssessment.value)
    await currentAssessment.value.processAssessment()
    if ("weight" in chartData.value && "muac" in chartData.value) {
      if (chartData.value.muac[0].chartType === "acfa") {
        zScoreLineData.value = await getZScoreLineData(currentAssessment.value)
      }
      tabTitles.value = [
        { value: "measurements", label: $gettext("Measurements") },
        { value: "muac", label: $gettext("Arm Size Growth Chart") },
      ]
    }
  }

  getData()

</script>

<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      {{ $gettext("Growth Assessment Details") }}
    </h1>

    <div
      v-if="tabTitles"
      class="tabs is-toggle is-toggle-rounded is-centered is-fullwidth mb-4"
    >
      <ul>
        <li
          v-for="tab in tabTitles"
          :key="tab.value"
          :class="{ 'is-active': currentTab == tab.value }"
        >
          <a @click="currentTab = tab.value">
            {{ tab.label }}
          </a>
        </li>
      </ul>
    </div>

    <template v-if="currentTab == 'measurements'">
      <p class="muted is-italic">
        {{ $gettext("Below are data from all the growth assessments for this child.") }}
        {{ $gettext("Tap the date of the assessment to see its recommendations.") }}
      </p>
      <table :class="{ table: true, 'is-narrow': width < 600 }">
        <caption>
          {{ showZScores ? $gettext("Z scores") : $gettext("Measurements") }}
          <a
            class="text-link"
            @click="showZScores = !showZScores; showMeasurements = !showMeasurements"
          >
            ({{ showZScores ? $gettext("Show measurements") : $gettext("Show z scores") }})
          </a>
        </caption>
        <thead>
          <tr :class="{ 'rotated-headers': width < 600 }">
            <td />
            <template v-if="showMeasurements">
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Weight") }} {{ $gettext("(kg)") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Height") }} {{ $gettext("(cm)") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Head Size") }} {{ $gettext("(cm)") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Arm Size") }} {{ $gettext("(cm)") }}
                  </span>
                </div>
              </th>
            </template>
            <template v-if="showZScores">
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Weight for length/height") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("BMI for age") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Weight for age") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Length/height for age") }}
                  </span>
                </div>
              </th>
              <th class="rotate">
                <div>
                  <span>
                    {{ $gettext("Head size for age") }}
                  </span>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="assessment in assessments"
            :key="assessment.id"
            :class="{ 'is-selected': assessment.id == assessmentId }"
          >
            <td>
              <router-link :to="{ name: 'GrowthRecommendations', params: { assessmentId: assessment.id, childId } }">
                {{ assessment.dateOfAssessment?.toLocaleDateString() }}
              </router-link>
              <br />
              {{ $gettext("Age:") }}
              {{ assessment.niceAge }}
            </td>
            <template v-if="showMeasurements">
              <td :class="{ 'has-warning-bg': assessment.weightIsDirty }">
                {{ assessment.weightInKilograms || "-" }}
                <sup v-if="assessment.weightIsDirty">
                  *
                </sup>
              </td>
              <td :class="{ 'has-warning-bg': assessment.lengthIsDirty }">
                {{ assessment.lengthInCm || "-" }}
                <sup v-if="assessment.lengthIsDirty">
                  *
                </sup>
              </td>
              <td :class="{ 'has-warning-bg': assessment.headSizeIsDirty }">
                {{ assessment.headCircumferenceInCm || "-" }}
                <sup v-if="assessment.headSizeIsDirty">
                  *
                </sup>
              </td>
              <td>
                {{ assessment.muacInCm || "-" }}
              </td>
            </template>
            <template v-if="showZScores">
              <td :class="{ 'has-warning-bg': assessment.weightIsDirty || assessment.lengthIsDirty }">
                {{ assessment.zScoreWflh }}
                <sup v-if="assessment.weightIsDirty || assessment.lengthIsDirty">
                  *
                </sup>
              </td>
              <td :class="{ 'has-warning-bg': assessment.weightIsDirty || assessment.lengthIsDirty }">
                {{ assessment.zScoreBmi }}
                <sup v-if="assessment.weightIsDirty || assessment.lengthIsDirty">
                  *
                </sup>
              </td>
              <td :class="{ 'has-warning-bg': assessment.weightIsDirty }">
                {{ assessment.zScoreWfa }}
                <sup v-if="assessment.weightIsDirty">
                  *
                </sup>
              </td>
              <td :class="{ 'has-warning-bg': assessment.lengthIsDirty }">
                {{ assessment.zScoreLhfa }}
                <sup v-if="assessment.lengthIsDirty">
                  *
                </sup>
              </td>
              <td :class="{ 'has-warning-bg': assessment.headSizeIsDirty }">
                {{ assessment.zScoreHca }}
                <sup v-if="assessment.headSizeIsDirty">
                  *
                </sup>
              </td>
            </template>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              :colspan="showMeasurements ? 5 : 6"
              class="is-italic"
            >
              * {{ $gettext("indicates measurements believed to have been inaccurate.") }}
            </td>
          </tr>
        </tfoot>
      </table>
    </template>

    <template v-if="currentTab == 'muac'">
      <MuacCutoffChart
        v-if="chartData.muac[0].chartType === 'muac_cutoff'"
        :assessment="currentAssessment"
      />
      <GrowthChart
        v-else
        :assessment="currentAssessment"
        :chart-data="chartData.muac[0]"
        :z-score-line-data="zScoreLineData"
      />
    </template>
    <div class="has-text-centered">
      <router-link
        class="text-link"
        :to="{ name: 'GrowthRecommendations', params: { assessmentId, childId } }"
      >
        {{ $gettext("Go back to recommendations") }}
      </router-link>
    </div>
  </article>
</template>
<style scoped>
  @media (max-width: 415px) {
    .tabs {
      font-size: 85%;
    }
  }
  @media (max-width: 500px) {
    .table {
      font-size: 85%;
    }
  }
  .table {
    caption {
      text-align: center;
      a.text-link {
        font-size: 66%;
        font-weight: normal;
      }
    }
    tbody a {
      font-weight: bold;
      text-decoration: underline;
    }
    tr.is-selected td.has-warning-bg {
      background-color: inherit;
      font-weight: bold;
    }
  }
</style>
