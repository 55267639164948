const chartMetadata = {
  wfl: {
    chartName: "wfl",
    title: "Weight for Length",
    xAxisTitle: "Length (cm)",
    yAxisTitle: "Weight (kg)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  wfh: {
    chartName: "wfh",
    title: "Weight for Height",
    xAxisTitle: "Height (cm)",
    yAxisTitle: "Weight (kg)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  bmi: {
    chartName: "bmi",
    title: "BMI for Age",
    xAxisTitle: "Age (months)",
    yAxisTitle: "BMI",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  wfa: {
    chartName: "wfa",
    title: "Weight for Age",
    xAxisTitle: "Age (months)",
    yAxisTitle: "Weight (kg)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  acfa: {
    chartName: "acfa",
    title: "Arm Size for Age",
    xAxisTitle: "Age (months)",
    yAxisTitle: "Arm Size (cm)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  lfa: {
    chartName: "lfa",
    title: "Length for Age",
    xAxisTitle: "Age (months)",
    yAxisTitle: "Length (cm)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  hfa: {
    chartName: "hfa",
    title: "Height for Age",
    xAxisTitle: "Age (months)",
    yAxisTitle: "Height (cm)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
  hcfa: {
    chartName: "hcfa",
    title: "Head Size for Age",
    xAxisTitle: "Age (months)",
    yAxisTitle: "Head Size (cm)",
    lines: ["-3", "-2", "-1", "0", "1", "2", "3"],
  },
}

export default chartMetadata
