<script lang="ts" setup>
import { useRoute } from "vue-router"
import { ref, computed } from "vue"
import { useWindowSize } from '@vueuse/core'

import { getChildById } from "@/services/Child"
import { getGrowthChartData } from "@/utils/GrowthCharts"
import { getGrowthAssessmentForChild } from "@/services/GrowthAssessment"
import { setCurrentViewContext } from "@/utils/GlobalState"
import { GrowthAssessment } from "@/models/GrowthAssessment"
import { gettext } from "@/utils/Translation"
import ModalWindow from "@/components/ModalWindow.vue"
import GrowthInterpretationsPanel from "@/components/assessments/GrowthInterpretationsPanel.vue"
import IconPencil from "@/components/svg/IconPencil.vue"
import ChildHeader from "@/components/children/ChildHeader.vue"
import LearnMore from "@/components/LearnMore.vue"
import RecommendationsReferrals from "@/components/assessments/RecommendationsReferrals.vue"
import StickyCard from "@/components/assessments/StickyCard.vue"
import PageLoading from "@/components/PageLoading.vue"
import AssessmentNotes from "@/components/assessments/AssessmentNotes.vue"

const route = useRoute()
const pageReady = ref(false)
const chartData = ref(null)
const currentTab = ref(null)
const growthAssessment = ref(null)
const child = ref(null)
const interpretationsByMetric = ref(null)
const recommendations = ref(null)
const referrals = ref(null)
const tabTitles = ref(null)
const showModal = ref(false)
const learnMoreContent = ref("")
const childId = parseInt(route.params.childId as string)
const assessmentId = parseInt(route.params.assessmentId as string)
const { $gettext } = gettext
const { width } = useWindowSize()
const tabletMinWidth = 625 // Should correspond with $tablet defined in _main.css
const isTabletOrWider = computed(() => width.value > tabletMinWidth)


async function getData() {
  child.value = await getChildById(childId)
  setCurrentViewContext(child.value, "child")
  growthAssessment.value = new GrowthAssessment(child.value, await getGrowthAssessmentForChild(assessmentId))
  await growthAssessment.value.processAssessment()
  chartData.value = await getGrowthChartData(growthAssessment.value)
  interpretationsByMetric.value = growthAssessment.value.interpretations
  const int = growthAssessment.value.interpretations
  if (int) {
    tabTitles.value = [
      { value: "weight", label: int.weight?.label, stoplightColor: int.weight?.stoplightColor, isVisible: "weight" in int },
      { value: "muac", label: int.muac?.label, stoplightColor: int.muac?.stoplightColor, isVisible: !("weight" in int) && "muac" in int },
      { value: "height", label: int.height?.label, stoplightColor: int.height?.stoplightColor, isVisible: "height" in int },
      { value: "headSize", label: int.headSize?.label, stoplightColor: int.headSize?.stoplightColor, isVisible: "headSize" in int },
    ]
    tabTitles.value = tabTitles.value.filter(t => t.isVisible)
    if (tabTitles.value.length) {
      currentTab.value = tabTitles.value[0].value
    }
  }
  recommendations.value = growthAssessment.value.recommendations
  referrals.value = growthAssessment.value.referrals
  pageReady.value = true
}

getData()
</script>

<template>
  <article
    v-if="pageReady"
    id="growth-recommendations"
    class="recommendations"
  >
    <ModalWindow
      :show-modal="showModal"
      :close-modal-function="() => showModal = false"
    >
      <LearnMore :content="learnMoreContent" />
    </ModalWindow>

    <ChildHeader
      v-if="child"
      :child="child"
    />


    <!-- eslint-disable vue/multiline-html-element-content-newline -->
    <h1
      v-if="growthAssessment"
      class="title"
    >
      {{ $gettext("Growth Recommendations") }}&nbsp;&nbsp;<router-link
        v-if="growthAssessment.isEditable"
        class="edit-assessment-icon"
        :to="{ name: 'GrowthDateOfAssessment', params: route.params }"
        :aria-label="$gettext('Edit assessment')"
        :title="$gettext('Edit assessment')"
      ><IconPencil /></router-link>
    </h1>
    <!-- eslint-enable vue/multiline-html-element-content-newline -->

    <div
      v-if="growthAssessment.hasDirtyData"
      class="message is-danger"
    >
      <p class="message-body m-0">
        {{ $gettext("Warning: the interpretations and recommendations below are based on one or more measurements that might be inaccurate.") }}
        <strong>
          {{ $gettext("Please refer to the most recent assessment for this child.") }}
        </strong>
      </p>
    </div>

    <!-- Referrals -->
    <RecommendationsReferrals :referrals="referrals" />

    <!-- Interpretations -->
    <section
      v-if="isTabletOrWider"
      class="tablet-plus"
    >
      <h2>{{ $gettext("Interpretations") }}</h2>
      <div
        v-if="interpretationsByMetric && tabTitles.length > 1"
        class="tabs is-toggle is-toggle-rounded is-centered is-fullwidth mb-6"
      >
        <ul>
          <li
            v-for="tab in tabTitles"
            :key="tab.value"
            :class="{ 'is-active': currentTab == tab.value }"
          >
            <a
              :class="`tab-${tab.value}`"
              @click="currentTab = tab.value"
            >
              <span :class="{ stoplight: true, red: tab.stoplightColor == 'red', green: tab.stoplightColor == 'green' }" />
              {{ tab.label }}
            </a>
          </li>
        </ul>
      </div>

      <GrowthInterpretationsPanel
        v-if="currentTab != 'recs'"
        :assessment="growthAssessment"
        :content="interpretationsByMetric[currentTab]"
        :chart-data="chartData[currentTab]"
        :dirty-data-excluded="chartData.dirtyDataWereExcluded"
        @current-tab="tab => { currentTab = tab }"
        @learn-more-content="content => { learnMoreContent = content; showModal = true }"
      />
    </section>
    <StickyCard v-else>
      <template #title>
        {{ $gettext("Interpretations") }}
      </template>
      <template #cardContent>
        <!-- XXX: the tabs are appearing above the sticky header. -->
        <div
          v-if="interpretationsByMetric && tabTitles.length > 1"
          class="m-3 tabs is-toggle is-toggle-rounded is-centered is-fullwidth"
        >
          <ul>
            <li
              v-for="tab in tabTitles"
              :key="tab.value"
              :class="{ 'is-active': currentTab == tab.value }"
            >
              <a
                :class="`tab-${tab.value}`"
                @click="currentTab = tab.value"
              >
                <span :class="{ stoplight: true, red: tab.stoplightColor == 'red', green: tab.stoplightColor == 'green' }" />
                {{ tab.label }}
              </a>
            </li>
          </ul>
        </div>
        <div class="p-3">
          <GrowthInterpretationsPanel
            v-if="currentTab != 'recs'"
            :assessment="growthAssessment"
            :content="interpretationsByMetric[currentTab]"
            :current-metric="currentTab"
            :chart-data="chartData[currentTab]"
            :dirty-data-excluded="chartData.dirtyDataWereExcluded"
            @current-tab="tab => { currentTab = tab }"
            @learn-more-content="content => { learnMoreContent = content; showModal = true }"
          />
        </div>
      </template>
    </StickyCard>

    <!-- Recommendations -->
    <StickyCard :include-on-wide="true">
      <template #title>
        {{ $gettext("Recommendations") }}
      </template>
      <template #cardContent>
        <div class="p-3-mobile">
          <ul class="list">
            <li
              v-for="rec in recommendations"
              :key="rec.contentId"
            >
              {{ rec.content }}
              <a
                v-if="rec.learnMore"
                class="learn-more"
                @click="learnMoreContent = rec.learnMore; showModal = true"
              >
                {{ $gettext("Learn more") }}
              </a>
            </li>
          </ul>
        </div>
      </template>
    </StickyCard>

    <!-- Assessment Notes -->
    <StickyCard :include-on-wide="true">
      <template #title>
        {{ $gettext("Assessment Notes") }}
      </template>
      <template #cardContent>
        <div class="p-3-mobile">
          <AssessmentNotes
            :assessment="growthAssessment"
            table-name="growthAssessments"
          />
        </div>
      </template>
    </StickyCard>

    <div
      v-if="growthAssessment"
      class="assessment-metadata"
    >
      <h2>
        {{ $gettext("About this assessment") }}
      </h2>
      <ul>
        <li>
          <span class="muted">
            {{ $gettext("Assessor") }}
          </span>
          {{ growthAssessment.creatorName || "-" }}
        </li>
        <li>
          <span class="muted">
            {{ $gettext("Date of assessment") }}
          </span>
          {{ growthAssessment.dateOfAssessment.toLocaleDateString() }}
        </li>
        <li>
          <span class="muted">
            {{ $gettext("Child's age at assessment") }}
          </span>
          {{ growthAssessment.niceAge }}
        </li>
      </ul>
      <router-link
        class="text-link"
        :to="{ name: 'GrowthRecommendationsAdvanced', params: { assessmentId, childId } }"
      >
        See advanced details
      </router-link>
      <template v-if="growthAssessment.isEditable">
        |
        <router-link
          class="text-link"
          :to="{ name: 'GrowthDateOfAssessment', params: route.params }"
        >
          {{ $gettext("Edit assessment") }}
        </router-link>
      </template>
    </div>
  </article>
  <PageLoading v-else />
</template>

<style scoped lang="scss">
  section.tablet-plus {
    margin-bottom: 2em;
  }

  @media (max-width: 415px) {
    .tabs {
      font-size: 85%;
    }
  }
</style>
