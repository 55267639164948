interface Args {
  label: string,
  help?: string,
  required?: boolean,
  autofocus?: boolean,
  rows?: number
}
export function getAssessmentNoteSchema({ label, help = null, required = false, autofocus = false, rows = 5 }: Args) {
  const field = {
    $formkit: "textarea",
    name: "note",
    id: "note",
    value: "$note",
    label,
    help,
    rows,
    autofocus,
  }
  if (required) {
    field["validation"] = "required"
    field["validation-visibility"] = "submit"
  }
  return [field]
}
